import React from "react"
import styled from "@emotion/styled"
const Loader = ({ title }) => {
  return (
    <Overlay>
      <OverlayBox>
        <svg
          width="50px"
          height="50px"
          viewBox="0 0 44 46"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-16.000000, -15.000000)" fill="#5558ea">
              <g>
                <path
                  d="M37.8928259,22.6666667 C31.2374565,22.6666667 25.5630371,26.9090167 23.4450565,32.8490222 C22.7340371,34.8431222 20.5410765,35.8832972 18.5469573,35.1722778 C16.5528318,34.4612583 15.5126766,32.2682722 16.2237018,30.2741722 C19.3959384,21.3773889 27.8947259,15 37.8928259,15 C44.3560176,15 50.2021704,17.670875 54.3759676,21.9591611 C55.8384482,23.4618278 55.8222204,25.8607278 54.3395509,27.3433972 L36.6004815,45.0824667 C35.1035009,46.5794472 32.6763621,46.5794472 31.1793815,45.0824667 C29.6823371,43.5854222 29.6823371,41.1582833 31.1793815,39.6613028 L45.9127982,24.9278861 C43.5791287,23.4924306 40.8340148,22.6666667 37.8928259,22.6666667 Z M18.5469573,40.8277222 C20.5410765,40.1167028 22.7340371,41.1568778 23.4450565,43.1509778 C25.5630371,49.0909833 31.2374565,53.3333333 37.8928259,53.3333333 C44.5482593,53.3333333 50.2226787,49.0909833 52.3406593,43.1509778 C53.0516787,41.1568778 55.2446009,40.1167028 57.2387648,40.8277222 C59.2328648,41.5387417 60.2730398,43.7317278 59.5620204,45.7258278 C56.3897454,54.6226111 47.8909898,61 37.8928259,61 C27.8947259,61 19.3959384,54.6226111 16.2237018,45.7258278 C15.5126766,43.7317278 16.5528318,41.5387417 18.5469573,40.8277222 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </OverlayBox>
      {title && <SubTitle>{title}</SubTitle>}
    </Overlay>
  )
}
export default Loader

const SubTitle = styled.div`
  margin-top: 20px;
`

const OverlayBox = styled.div`
  background: ${({ theme }) => theme.colors.loader.background};
  z-index: 1010;
  color: ${({ theme }) => theme.colors.loader.color};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 100%;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 16px 5px;

  p {
    font-size: 16px;
    font-weight: 500;
  }

  svg {
    margin: 0;

    animation: rotate 1.5s linear infinite;

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`

const Overlay = styled.div`
  background: linear-gradient(to bottom, #a2a2a22b, #f9f9fb4a);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 40;
`
