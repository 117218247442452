import React, { useContext } from "react"
import styled from "@emotion/styled"
import { useTheme } from "emotion-theming"
import Icon from "../../Icon/Icon"
import { QuoteContext } from "../../../Context/QuoteContext"
import { bool } from "prop-types"
import Layout from "../../Layout"
import SEO from "../../seo"

const QuoteLandingPage = ({ invalidGuid }) => {
  const { state } = useContext(QuoteContext)
  const theme = useTheme()

  if (state.loadedQuoteFailed.failed || invalidGuid) {
    return (
      <Layout>
        <SEO title={`evoli - Något gick fel`} noIndex={true} />
        <QuoteContainer>
          <QuoteHeroContent>
            <Icon
              name="stop"
              bgColor={theme.colors.CardContainer.iconBackground}
              iconColor={theme.colors.CardContainer.iconColor}
              iconSize="25"
            />
            <ContentHeader style={{ maxWidth: "190px" }}>
              Något gick fel
            </ContentHeader>
            <ContentParagraph style={{ maxWidth: "250px" }}>
              {state.hasFetchedQuote &&
                (state.loadedQuoteFailed.message ||
                  "Prova att öppna länken igen, om felet kvarstår kontakta vår support.")}
              {invalidGuid &&
                "Prova att öppna länken igen, om felet kvarstår kontakta vår support."}
            </ContentParagraph>

            <OutlinedButton href="https://support.evoli.se">
              Gå till support
            </OutlinedButton>
          </QuoteHeroContent>
        </QuoteContainer>
      </Layout>
    )
  }

  return <></>
}
QuoteLandingPage.propTypes = {
  invalidGuid: bool.isRequired,
}
export default QuoteLandingPage

const QuoteContainer = styled.section`
  background: ${props => (props.bgColor ? props.bgColor : "none")};
  width: 100%;
  padding: 50px 40px 80px 40px;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    margin: 0;
    text-align: left;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    padding: 50px 40px 80px 40px;
    margin: 0;
    text-align: left;
    flex-direction: column;
    min-height: calc(100vh - 46px);
  }

  @media (max-width: 600px) and (min-width: 414px) {
    padding: 30px 20px;
    margin: 0;
    text-align: left;
    flex-direction: column;
    justify-content: flex-start;
  }

  @media (max-width: 375px) {
    padding: 30px 16px;
    margin: 0;
    text-align: left;
    flex-direction: column;
    min-height: calc(100vh - 46px);
  }

  @media (max-width: 320px) {
    padding: 40px 15px;
    margin: 0;
    text-align: left;
    flex-direction: column;
  }
`

const QuoteHeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  max-width: 280px;

  img {
    width: 120px;
    margin: 0;
    margin-top: 30px;
  }
`
const ContentHeader = styled.h1`
  margin: 0;
  max-width: 200px;
  margin-top: 10px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #ff4984;

  span {
    display: block;
    text-transform: uppercase;
    color: #5558ea;
    margin-top: 5px;
  }
`
const ContentParagraph = styled.p`
  margin-top: 5px;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: center;
  color: #63638f;
  margin-bottom: 20px;
`

const OutlinedButton = styled.a`
  border: 1px solid #01cc86;
  padding: 10px 20px;
  border-radius: 8px;
  background: white;
  color: #01cc86;
  font-size: 12px;
  margin-top: 30px;
  cursor: pointer;
`
