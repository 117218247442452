import React, { useContext, useEffect, useCallback, useState } from "react"
import { navigate } from "gatsby"
import { QuoteContext } from "../Context/QuoteContext"
import { loadQuote, toggleHasFetchedQuote } from "../Context/Actions"
import { isGuid, isNullOrWhitespace } from "../utils/HelperFunctions"
import QuoteLandingPage from "../components/PageComponents/QuoteLandingPage/QuoteLandingPage"
import Loader from "../components/GlobalAssets/Loader"

const ProductPaymentPage = ({ location }) => {
  const { state, dispatch } = useContext(QuoteContext)
  const [invalidGuid, setInvalidGuid] = useState(false)
  const params = new URLSearchParams(location.search)

  const guid = params.get("g")
  const e = params.get("e")
  const paymentid = params.get("paymentid")

  const stableDispatch = useCallback(dispatch, [])

  // useEffect(() => {
  //   if (isNullOrWhitespace(guid) || !isGuid(guid)) {
  //     setInvalidGuid(true)
  //     return
  //   }

  //   stableDispatch(toggleHasFetchedQuote())
  //   stableDispatch(loadQuote({ guid: guid }))
  // }, [stableDispatch, guid])

  useEffect(() => {
    if (isNullOrWhitespace(guid) || !isGuid(guid)) {
      setInvalidGuid(true)
      return
    }

    stableDispatch(toggleHasFetchedQuote())

    if (!isNullOrWhitespace(paymentid)) {
      stableDispatch(loadQuote({ guid: `${guid}/${paymentid}` }))
      return
    }

    if (!isNullOrWhitespace(e)) {
      stableDispatch(loadQuote({ guid: `${guid}/${e}` }))
      return
    }

    stableDispatch(loadQuote({ guid: guid }))
  }, [stableDispatch, guid, e, paymentid])

  useEffect(() => {
    if (state.hasLoadedQuote && !state.loadedQuoteFailed.failed) {
      const queryString = new URLSearchParams(location.search).toString()

      if (state.quote.result.redirectUrl) {
        navigate(state.quote.result.redirectUrl, {
          referrerPolicy: "no-referrer",
          replace: true,
        })
      } else {
        navigate(`/forsakring/bekraftelse?${queryString}`)
      }

      // switch (state.quote.result.quoteStatus) {

      //   case "active":

      //     navigate(`/forsakring/bekraftelse?${queryString}`)

      //     break

      //   default:
      //     navigate(`/404`)
      //     break
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loadedQuote])

  if (state.loadedQuoteFailed.failed || invalidGuid) {
    return <QuoteLandingPage invalidGuid={invalidGuid} />
  }

  return <Loader />
}

export default ProductPaymentPage
